import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';

import './Header.css';
import { Autoplay, Navigation } from 'swiper/modules';

import Image1 from '../../assets/car 1.webp'
import Image2 from '../../assets/car 2.webp'
import Image3 from '../../assets/car 3.webp'
import Image4 from '../../assets/car 4.webp'
import Image5 from '../../assets/car 5.webp'
import Image6 from '../../assets/1 c.webp'
import Image7 from '../../assets/2 c.webp'
import Image8 from '../../assets/car 6.webp'
// import Image3 from '../../assets/3.jfif'

const Header = () => {
    return (
        <>
            <section className='section header'>

                {/* slider */}
                <Swiper slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}

                    pagination={{
                        clickable: true,
                    }}
                    
                    controller={{
                        inverse: true,
                    }}
                    
                    navigation={{ // activate navigation with navigation buttons
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                    
                    slideToClickedSlide={true}
                    
                    modules={[Autoplay, Navigation]}
                    
                    className="swiper header">

                    {/* slide button */}
                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>

                    {/* image content  */}
                    <SwiperSlide>
                        <img src={Image1} alt="" className='img-slider' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Image2} alt="" className='img-slider' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Image3} alt="" className='img-slider' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Image4} alt="" className='img-slider' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Image5} alt="" className='img-slider' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Image6} alt="" className='img-slider' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Image7} alt="" className='img-slider' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Image8} alt="" className='img-slider' />
                    </SwiperSlide>
                    {/* <SwiperSlide><img src={Image3} alt="" className='img' /></SwiperSlide> */}

                    
                </Swiper>
                {/* slider ends */}

            </section >
        </>
    )
}

export default Header
