import React from 'react'
import Courses from '../components/Courses/Courses';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import NavBar from '../components/Navigation bar/NavBar';
import About from '../components/About/About';
import AboutDirector from '../components/About/AboutDirector';
import ContactSection from '../components/ContactSection/ContactSection';
import { Helmet } from 'react-helmet';

// import ModalComponent from '../components/Modal/ModalComponent';


const Home = () => {



    return (
        <>
            <Helmet>
                <title>Bansal Coaching Classes, Sagar(M.P)</title>
                <meta name="description" content="Bansal Coaching Classes in Sagar - Leading institute for JEE and NEET coaching. Our experienced faculty, comprehensive study material, and personalized guidance ensure success in competitive exams. Join us for quality education and excel in your JEE and NEET aspirations." />
            </Helmet>

            <NavBar />
            <Header />
            <About />
            <AboutDirector />
            <Courses />
            <ContactSection />
            <Footer />
        </>
    )
}

export default Home