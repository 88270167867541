// import './App.css';
import AboutSection from './pages/About/AboutSection';
import Home from './pages/Home'
import { Routes, Route } from 'react-router-dom'
import StudentReg from './pages/Registration Pages/StudentReg';
import BoostsReg from './pages/Registration Pages/BoostsReg';

import ContactPage from './pages/ContactPage';

import JeeAdvanced from './pages/Courses/JeeAdvanced'

import Neet from './pages/Courses/Neet';
import AllCourses from './pages/Courses/AllCourses';
import PreFoundation from './pages/Courses/PreFoundation';
import Privacy from './pages/Privacy';

import Terms from './pages/Terms';
import MissionVision from './pages/About/MissionVision';
import BoostPage from './pages/BoostPage.jsx'
// import CourseDescription from './components/Courses/CourseDescription.jsx';
import JeeBulls from './components/Courses/JEECourses/JeeBulls.jsx';
import JeeNucleus from './components/Courses/JEECourses/JeeNucleus.jsx';
import JeeSterling from './components/Courses/JEECourses/JeeSterling.jsx';
import NeetBulls from './components/Courses/NeetCourses/NeetBulls.jsx';
import NeetNucleus from './components/Courses/NeetCourses/NeetNucleus.jsx';
import NeetSterling from './components/Courses/NeetCourses/NeetSterling.jsx';

import DiresctorsMsg from './pages/About/DiresctorsMsg.jsx';
import CustomPopUp from './components/PopUp/CustomPopUp';
import Banner from './assets/new_popup.jpg'
import { useEffect, useState } from 'react';
import Whatsapp from './components/whatsapp/Whatsapp.jsx';

function App() {

  const [visibility, setVisibility] = useState(false);

  const popupCloseHandler = (e) => {
    setVisibility(e);
  };

  useEffect(() => {
    setVisibility(true);
  }, [])

  useEffect(() => {
    document.title = 'Bansal Classes Sagar - Home';
  }, []);

  return (
    <>
      <Whatsapp/>
      <CustomPopUp
        onClose={popupCloseHandler}
        show={visibility}
      
      >
        <img src={Banner} alt="" />
      </CustomPopUp>
      <Routes>
        {/* Home Page  */}
        <Route path="/" element={<Home />} />

        {/* Contact us Page  */}
        <Route path='/contact' element={<ContactPage />} />

        {/* about section  */}
        <Route path="/aboutus" element={<AboutSection />} />
        <Route path="/mision&vision" element={<MissionVision />} />
        <Route path="/directors-msg" element={<DiresctorsMsg />} />

        {/* <Route path='about-dir' element={<AboutSection />} /> */}

        {/* Registrations form section  */}
        <Route path='/student-registration' element={<StudentReg />} />
        <Route path='/boost-registration' element={<BoostsReg />} />

        {/* Courses section  */}
        <Route path='/jeeadvanced' element={<JeeAdvanced />} />
        <Route path='/neet-course' element={<Neet />} />
        <Route path='/all-course' element={<AllCourses />} />
        <Route path='/pre-foundation' element={<PreFoundation />} />

        {/* Privacy Policy  */}
        <Route path='/privacy-policy' element={<Privacy />} />
        <Route path='/terms-conditions' element={<Terms />} />

        {/* Boosts section  */}
        <Route path='/boost' element={<BoostPage />} />

        <Route path='/jee-bulls-eye' element={<JeeBulls />} />
        <Route path='/jee-nucleus' element={<JeeNucleus />} />
        <Route path='/jee-sterling' element={<JeeSterling />} />

        <Route path="/neet-bulls-eye" element={<NeetBulls />} />
        <Route path="/neet-nucleus" element={<NeetNucleus />} />
        <Route path="/neet-sterling" element={<NeetSterling />} />
      </Routes>



    </>
  );
}

export default App;
